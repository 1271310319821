"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalendar = void 0;
var react_1 = require("react");
var date_fns_1 = require("date-fns");
var useCrollIntoView_1 = require("../../hooks/useCrollIntoView");
var useOutsideClick_1 = require("../../hooks/useOutsideClick");
var date_utils_1 = require("../../utils/date.utils");
var useCalendar = function (selectDateHandler, handleYearSelection) {
    var _a = (0, react_1.useState)([]), calendarDays = _a[0], setCalendarDays = _a[1];
    var _b = (0, react_1.useState)(false), isYearListOpen = _b[0], setIsYearListOpen = _b[1];
    var _c = (0, react_1.useState)(new Date()), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = (0, react_1.useState)(new Date()), currentMonth = _d[0], setCurrentMonth = _d[1];
    var _e = (0, react_1.useState)(null), activeNavigator = _e[0], setActiveNavigator = _e[1];
    var _f = (0, react_1.useState)(new Date().getFullYear()), selectedYear = _f[0], setSelectedYear = _f[1];
    var calendarRef = (0, react_1.useRef)(null);
    var selectedYearRef = (0, useCrollIntoView_1.useScrollIntoView)(isYearListOpen);
    // functions responsible to handle year selection
    var toggleYearSelectList = function () { return setIsYearListOpen(function (prev) { return !prev; }); };
    var selectYearHandler = function (year) {
        handleYearChange(year);
        setIsYearListOpen(false);
    };
    // global click outside of targeted element handler
    (0, useOutsideClick_1.useOutsideClick)(calendarRef, function () { return setIsYearListOpen(false); });
    // highlight date handlers
    var isSelectedDay = function (day) { return (0, date_fns_1.isSameDay)(day, selectedDate); };
    var isTodayHandler = function (day) { return (0, date_fns_1.isToday)(day); };
    (0, react_1.useEffect)(function () {
        setCalendarDays((0, date_utils_1.generateCalendarDays)(currentMonth));
    }, [currentMonth]);
    var handleMonthChange = function (direction) {
        setCurrentMonth(direction === "next"
            ? (0, date_fns_1.addMonths)(currentMonth, 1)
            : (0, date_fns_1.subMonths)(currentMonth, 1));
        setActiveNavigator(direction);
    };
    // handle date selection
    var datePickHandler = function (date) {
        setSelectedDate(date);
        setCurrentMonth(date);
        selectDateHandler(date);
    };
    var handleYearChange = function (year) {
        var newDate = (0, date_fns_1.setYear)(selectedDate, year);
        setSelectedDate(newDate);
        setCurrentMonth(function (prev) { return (0, date_fns_1.setYear)(prev, year); });
        setSelectedYear(year);
        selectDateHandler(newDate);
        handleYearSelection(year);
    };
    var mergeStyles = function (defaultStyles, customStyles) {
        return __assign(__assign({}, defaultStyles), customStyles);
    };
    return {
        isYearListOpen: isYearListOpen,
        toggleYearSelectList: toggleYearSelectList,
        selectYearHandler: selectYearHandler,
        isSelectedDay: isSelectedDay,
        isTodayHandler: isTodayHandler,
        selectedYearRef: selectedYearRef,
        calendarRef: calendarRef,
        calendarDays: calendarDays,
        handleMonthChange: handleMonthChange,
        currentMonth: currentMonth,
        selectedYear: selectedYear,
        datePickHandler: datePickHandler,
        mergeStyles: mergeStyles,
        activeNavigator: activeNavigator,
    };
};
exports.useCalendar = useCalendar;
