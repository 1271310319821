import React from 'react'

export default function DizeeModal({ isOverlayVisible, setOverlayVisible, children }) {
    const token = localStorage.getItem('dizeeToken');
    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            {children}
        </div>
    )
}
