import React from 'react'

export default function Youtube(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <path
                fill="#fff"
                d="M14.85 4.499s-.146-1.034-.597-1.488c-.571-.598-1.21-.6-1.503-.636-2.098-.152-5.247-.152-5.247-.152h-.006s-3.15 0-5.247.152c-.293.035-.932.038-1.503.636-.451.454-.595 1.488-.595 1.488S0 5.715 0 6.928v1.136c0 1.213.15 2.43.15 2.43s.146 1.033.594 1.487c.571.598 1.321.578 1.655.642 1.202.114 5.101.15 5.101.15s3.152-.006 5.25-.156c.293-.035.932-.038 1.503-.636.451-.454.598-1.488.598-1.488S15 9.28 15 8.064V6.928c0-1.213-.15-2.429-.15-2.429Zm-8.9 4.945V5.23l4.052 2.115-4.052 2.1Z"
            />
        </svg>
    )
}
