import { useNavigate } from "react-router";
import Check from "../../../../../svg/Check";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { setFocusMusic } from "../../../../../store/focuspage/focuspageSlice";
import { useEffect, useState } from "react";

const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = l - c / 2;
    let r = 0, g = 0, b = 0;
    if (h >= 0 && h < 60) {
        r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
        r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
        r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
        r = x; g = 0; b = c;
    } else if (h >= 300 && h < 360) {
        r = c; g = 0; b = x;
    }
    r = Math.round((r + m) * 255).toString(16).padStart(2, '0');
    g = Math.round((g + m) * 255).toString(16).padStart(2, '0');
    b = Math.round((b + m) * 255).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`;
};


export default function Step3() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let music = useSelector(state => state.focuspage.music)
    const [isValid, setIsValid] = useState(false);
    const [brightness, setBrightness] = useState(50)
    const [hue, setHue] = useState(0); // Hue state for color sliders
    const [color, setColor] = useState("FFFFFF")

    useEffect(() => {
        if (music?.background) {
            setIsValid(true)
        }
    }, [])


    const isValidHex = (hex) => {
        return /^([0-9A-F]{3}){1,2}$/i.test(hex);
    };

    const handleChange = (e) => {
        const value = e.target.value.toUpperCase();
        dispatch(setFocusMusic({ background: value }));
        if (isValidHex(value)) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
    };

    // Handle hue change
    const handleHueChangeBackground = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
        setColor(colorValue)
    };

    // Handle brightness change
    const handleBrightnessChangeBackground = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
        setColor(colorValue)
    };

    return (
        <LayoutHeader>

            <div className="flex flex-col justify-center  h-[85vh]">
                <div className="font-default mb-9">Select main color</div>

                <div className="flex flex-col justify-center m-3">
                    <input
                        type="range"
                        min="0"
                        max="360"
                        value={hue}
                        onChange={handleHueChangeBackground}
                        className="hue-slider"
                        style={{ width: "270px" }}
                    />

                </div>

                <div className="flex flex-col justify-center m-3">
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={brightness}
                        onChange={handleBrightnessChangeBackground}
                        className="brightness-slider"
                        style={{
                            background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                            margin: 0,
                            width: "270px"
                        }}
                    />
                </div>

                <div className="font-default flex">
                    <span className="m-2">HEX</span>
                    <span className="m-2 w-[52px]">#{color}</span>
                    <span className={`m-2 w-[16px] h-[16px]`} style={{ backgroundColor: `#${color}` }}></span>
                </div>

                {/* <div className="mb-20 flex font-default">
                    #
                    <DizeeInput
                        placeholder={"FFFFFF"}
                        onChange={handleChange}
                        value={music?.background}
                    />
                    {music?.background !== undefined && music?.background !== "" && isValid && <Check className="h-[12px] w-[18px] ml-2" />}
                </div> */}
                <div className="font-default flex my-10">
                    <div
                        className="cursor-pointer mr-7"
                        onClick={() => music?.background !== undefined && music?.background !== "" && isValid && navigate("/focus-page/music-type/step4")}
                    >
                        Next
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate("/focus-page/music-type/step2")}>
                        Go back
                    </div>
                </div>

                <div className="font-default flex-col mt-10">
                    <p>This will be used as the main color in your dizee</p>
                    <br />
                    <p>brand kit, used for backgrounds</p>
                </div>
            </div>
        </LayoutHeader>

    );
};

