"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOutsideClick = void 0;
var react_1 = require("react");
var useOutsideClick = function (ref, handler) {
    (0, react_1.useEffect)(function () {
        var listener = function (event) {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler();
        };
        document.addEventListener("mousedown", listener);
        return function () { return document.removeEventListener("mousedown", listener); };
    }, [ref, handler]);
};
exports.useOutsideClick = useOutsideClick;
