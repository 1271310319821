import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BottomBar() {
    const navigate = useNavigate(); // Use useNavigate hook for navigation

    return (
        <div className="fixed max-w-[350px] bottom-0 w-full ">
            <div className="flex justify-between items-center bg-black py-4 font-[410] text-[7px] tracking-[0.02em] leading-[8.26px] text-white">
                <button
                    className="text-center py-2  rounded-lg flex justify-center items-center"
                    onClick={() => console.log('Notification')}
                >
                    <div className='h-[5px] w-[5px] rounded bg-[#FF2300] mr-[5px] mt-[1px]'></div>NOTIFICATION
                </button>
                <button
                    className="text-white text-center py-2  rounded-lg"
                    onClick={() => navigate('/search')} // Correctly navigate to the search page
                >
                    SEARCH
                </button>
                <button
                    className="text-center py-2  rounded-lg"
                    onClick={() => console.log('Profile')}
                >
                    PROFILE
                </button>
            </div>
        </div>
    );
}
