import React, { useState } from 'react';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { useLocation, useNavigate } from 'react-router';
import DizeeButton from '../../../../components/DizeeComponent/DizeeButton';
import DizeeSelect from '../../../../components/DizeeComponent/DizeeSelect';

export default function PlayBackOption() {
    const navigate = useNavigate();
    const location = useLocation();
    const [playback, setPlayback] = useState(true);

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Playback options</p>
                    <div className='flex gap-x-4'>
                        <DizeeButton onClick={() => navigate('/add-section/music/publish', { state: {...location.state, playback} })} label={'Next'} />
                        <DizeeButton onClick={() => navigate(-1)} label={'Go back'} />
                    </div>
                </div>
                {
                    <div className='w-full'>
                        <DizeeSelect selected={playback} txt={'Enable'} onSelect={() => setPlayback(true)} checkmark={true}/>
                        <DizeeSelect selected={!playback} txt={'Disable'} onSelect={() => setPlayback(false)} checkmark={true}/>
                    </div>
                }
            </div>
        </LayoutHeader>
    );
}
