import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import search from '../../../../assets/images/components/search.png';
import SelectionCard from '../../../../components/SelectionCard';
import LayoutHeader from '../../../../layout/LayoutHeader';
import ImageSelectionCard from '../../../../components/ImageSelectionCard';
import { uploadImage } from '../../../../utils/upload';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { addEventHeaderToSectionThunk, deleteEventHeaderToSectionThunk } from '../../../../store/addsection/addsectionThunk';
import { clearEventHeader } from '../../../../store/addsection/addsectionSlice';
import { getAllSectionsThunk } from '../../../../store/addsection/addsectionThunk';

export default function AddEvent() {
    const token = localStorage.getItem('dizeeToken')
    const dispatch = useDispatch();
    const state = useSelector((state) => state)

    const [imagePreview, setImagePreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (state.addsection.other) {
            setImagePreview(state.addsection.other.event)
        }
    }, [])


    const handleImageChange = async (event) => {
        const file = event.target.files[0];

        let url = '';
        dispatch(setLoader(true))

        try {
            if (file) {
                url = await uploadImage(file)
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'))
                    return
                }
            }

            dispatch(addEventHeaderToSectionThunk({ token: token, payload: url })).then((res) => {
                if (res.payload.status === 200) {
                    console.log("Header image saved successfully")
                }
            })

        } catch (error) {
            console.error('Error uploading image or adding music:', error);
        } finally {
            dispatch(setLoader(false))
        }

        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = async () => {
        try {
            await dispatch(deleteEventHeaderToSectionThunk({ token: token })).then((res) => {

                if (res.payload.status === 200) {
                    console.log("Header image deleted successfully")
                    dispatch(clearEventHeader())
                }
            })
            setSelectedImage(null);
            setImagePreview(null);
        } catch (error) {
            console.error('Error changing image:', error);
        } finally {
            dispatch(setLoader(false))
        }
    };

    return (
        <LayoutHeader>

            <div className="w-[390px] h-auto bg-black flex flex-col justify-start items-center relative">

                <div className='px-4 my-[50px] flex  w-full justify-between'>
                    <p className='text-white' style={{ fontSize: '12px' }}>Add Event</p>
                    <Link to="/add-section">
                        <p className='text-white cursor-pointer ' style={{ fontSize: '12px' }}>Go Back</p>
                    </Link>
                </div>

                <ImageSelectionCard
                    txt="Add a header image"
                    dotimgclss={false}
                    onImageChange={handleImageChange}
                    imagePreview={imagePreview}
                    resetImage={resetImage}
                />
                <SelectionCard txt="Add events" dotimgclss={false} link={'/add-section/custom-event-link'} />
                <SelectionCard txt="Bulk import events" dotimgclss={false} link={'/add-section/bulk-import-events'} />
                {/* <SelectionCard txt="Manual event entry" dotimgclss={false} link={'/add-section/import-single-event'} /> */}


            </div>
        </LayoutHeader>

    )
}
