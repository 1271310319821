"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDatePicker = void 0;
var react_1 = require("react");
var useOutsideClick_1 = require("../../hooks/useOutsideClick");
var useDatePicker = function (isCalendarVisible, dateChangeHandler, selectYearHandler) {
    var _a = (0, react_1.useState)(new Date()), selectedDate = _a[0], setSelectedDate = _a[1];
    var _b = (0, react_1.useState)(new Date().getFullYear()), selectedYear = _b[0], setSelectedYear = _b[1];
    var _c = (0, react_1.useState)(isCalendarVisible || false), isCalendarOpen = _c[0], setIsCalendarOpen = _c[1];
    var datePickerElementRef = (0, react_1.useRef)(null);
    var calendarVisibilityHandler = function () { return setIsCalendarOpen(function (prev) { return !prev; }); };
    (0, useOutsideClick_1.useOutsideClick)(datePickerElementRef, function () { return setIsCalendarOpen(false); });
    var selectDateHandler = function (date) {
        setSelectedDate(date);
        if (dateChangeHandler)
            dateChangeHandler(date);
    };
    var handleYearSelection = function (year) {
        setSelectedYear(year);
        if (selectYearHandler)
            selectYearHandler(year);
    };
    return {
        selectedDate: selectedDate,
        selectedYear: selectedYear,
        isCalendarOpen: isCalendarOpen,
        datePickerElementRef: datePickerElementRef,
        calendarVisibilityHandler: calendarVisibilityHandler,
        selectDateHandler: selectDateHandler,
        handleYearSelection: handleYearSelection,
    };
};
exports.useDatePicker = useDatePicker;
