"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimePicker = void 0;
var date_fns_1 = require("date-fns");
var react_1 = require("react");
var useCrollIntoView_1 = require("../../hooks/useCrollIntoView");
var useOutsideClick_1 = require("../../hooks/useOutsideClick");
var useTimePicker = function (selectTimeHandler, timeListOpen) {
    var _a = (0, react_1.useState)(new Date()), selectedTime = _a[0], setSelectedTime = _a[1];
    var _b = (0, react_1.useState)(timeListOpen), isTimeListOpen = _b[0], setIsTimeListOpen = _b[1];
    var selectedHourRef = (0, useCrollIntoView_1.useScrollIntoView)(isTimeListOpen);
    var selectedMinutesRef = (0, useCrollIntoView_1.useScrollIntoView)(isTimeListOpen);
    var timePickerRef = (0, react_1.useRef)(null);
    var handleOutsideClick = function () { return setIsTimeListOpen(false); };
    (0, useOutsideClick_1.useOutsideClick)(timePickerRef, handleOutsideClick);
    var timeListVisibilityHandler = function () {
        setIsTimeListOpen(function (prev) { return !prev; });
    };
    // highlight hours handlers
    var isSelectedHour = function (hourDate) {
        return selectedTime.getHours() === hourDate.getHours();
    };
    // highlight minutes handlers
    var isSelectedMinutes = function (minutesDate) {
        return selectedTime.getMinutes() === minutesDate.getMinutes();
    };
    // hours change handler
    var hourChangeHandler = function (hourDate) {
        var newTime = (0, date_fns_1.setHours)(selectedTime, hourDate.getHours());
        setSelectedTime(newTime);
        selectTimeHandler(newTime);
    };
    // minutes change handler
    var minutesChangeHandler = function (minuteDate) {
        var newTime = (0, date_fns_1.setMinutes)(selectedTime, minuteDate.getMinutes());
        setSelectedTime(newTime);
        selectTimeHandler(newTime);
    };
    var mergeStyles = function (defaultStyles, customStyles) { return (__assign(__assign({}, defaultStyles), customStyles)); };
    return {
        isTimeListOpen: isTimeListOpen,
        timeListVisibilityHandler: timeListVisibilityHandler,
        selectedTime: selectedTime,
        isSelectedHour: isSelectedHour,
        isSelectedMinutes: isSelectedMinutes,
        hourChangeHandler: hourChangeHandler,
        minutesChangeHandler: minutesChangeHandler,
        selectedHourRef: selectedHourRef,
        selectedMinutesRef: selectedMinutesRef,
        timePickerRef: timePickerRef,
        mergeStyles: mergeStyles,
    };
};
exports.useTimePicker = useTimePicker;
