import React, { useEffect, useState } from 'react'
import { loginWithOtp, sendOtpToEmailThunk, loginPhoneNumberVerify } from '../../store/auth/authThunk';
import { useLocation, useNavigate } from 'react-router';
import DizeeInput from '../../components/DizeeComponent/DizeeInput';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../layout';
import { clearAlerts, setErrorAlert, setSuccessAlert } from '../../store/alert/alertSlice';

export default function Otp() {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const location = useLocation();

    const [code, setCode] = useState("");
    const { email } = location.state || {};
    const { phone } = useSelector((state) => state.user);
    
    useEffect(() => {
        dispatch(clearAlerts())
        dispatch(loginPhoneNumberVerify({ phone, email })).then((res) => {
        });
    }, [dispatch, phone]);

    useEffect(() => {
        if (code.length === 6) {
            dispatch(clearAlerts())
            dispatch(loginWithOtp({ email, code, phone })).then((res) => {
                if (res.payload.status === 200) navigation("/");
            });
        }
    }, [code, dispatch, phone, navigation]);

    const handleSendToEmail = () => {
        dispatch(sendOtpToEmailThunk(email))
          .then((res) => {
            if (res.payload.status === 200) {
                navigation('/login-otp-email')
            }
          })
          .catch((err) => {
            dispatch(
              setErrorAlert(
                err?.response?.data?.message || "Text verification code is not sent"
              )
            );
          });
    };

    return (
        <Layout>
            <div>
                <div className="font-default mb-9">Code has been sent to (****) *** {phone?.slice(-4)}</div>
                <div className="mb-20">
                    <DizeeInput
                        placeholder={"Enter the code"}
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                    />
                </div>
                <div className="font-default flex justify-between">
                    <button type="submit">Confirm</button>
                    <button type="button" onClick={handleSendToEmail}>
                        Send to email
                    </button>
                </div>

                <div className="absolute bottom-[30px]">
                    <div className="font-[410] text-[10px] leading-[11.8px] tracking-[3px]">
                        DIZEE
                    </div>
                </div>
            </div>
        </Layout>

    );
}
