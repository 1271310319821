import React from 'react'

export default function AppleMusic(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            {...props}
        >
            <path
                fill="#fff"
                d="M15.066 3.847c0-.464-.04-.925-.15-1.377-.199-.821-.667-1.45-1.369-1.91a3.16 3.16 0 0 0-1.18-.457 6.29 6.29 0 0 0-.98-.093c-.025-.002-.052-.008-.08-.01h-7.55a9.755 9.755 0 0 1-.286.017c-.469.027-.935.076-1.377.252C1.256.601.65 1.18.296 2.014a3.427 3.427 0 0 0-.228.885 6.199 6.199 0 0 0-.063.741c0 .02-.005.04-.005.059v7.676l.015.265c.032.513.098 1.02.314 1.492.407.89 1.092 1.477 2.031 1.759.262.08.538.117.812.142.349.034.697.04 1.045.04h6.928c.328 0 .657-.023.986-.065.517-.066 1.003-.218 1.442-.507.528-.349.925-.81 1.18-1.386.118-.266.184-.547.233-.832.071-.425.086-.854.086-1.283-.002-2.385 0-4.77-.002-7.154h-.004ZM11.033 6.35v3.587c0 .262-.037.52-.155.758a1.33 1.33 0 0 1-.87.716 2.84 2.84 0 0 1-.673.108 1.168 1.168 0 0 1-1.219-.964c-.09-.486.142-1.02.652-1.27.201-.099.42-.158.638-.202.238-.051.476-.098.711-.152a.387.387 0 0 0 .321-.324.495.495 0 0 0 .013-.12v-3.42a.472.472 0 0 0-.017-.115c-.025-.096-.093-.152-.191-.148-.101.005-.2.022-.297.042-.479.093-.957.19-1.433.287l-2.323.469c-.01.002-.022.007-.032.007-.174.05-.235.128-.243.31-.002.026 0 .053 0 .08-.002 1.634 0 3.268-.002 4.902 0 .265-.03.525-.135.77-.174.402-.483.655-.9.775-.22.064-.446.1-.677.108a1.156 1.156 0 0 1-1.205-.969c-.088-.51.145-1.06.724-1.305a3.8 3.8 0 0 1 .696-.194l.54-.11c.24-.051.366-.203.378-.449V3.856c0-.078.01-.157.027-.233.044-.179.172-.282.343-.324.16-.042.324-.071.486-.106.46-.093.92-.186 1.38-.277l1.426-.29c.422-.083.841-.169 1.263-.252.138-.027.277-.057.417-.066.194-.017.329.105.348.301.005.047.008.094.008.14v3.602Z"
            />
        </svg>
    )
}
