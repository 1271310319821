"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollIntoView = void 0;
var react_1 = require("react");
var useScrollIntoView = function (trigger) {
    var elementRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (trigger && elementRef.current) {
            elementRef.current.scrollIntoView({ block: "center" });
        }
    }, [trigger]);
    return elementRef;
};
exports.useScrollIntoView = useScrollIntoView;
