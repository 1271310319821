import React from 'react'

export default function Vimeo(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M7.5 15a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15ZM4.078 6.422 3.75 6S5.016 4.5 6 4.312c.818-.185.987.896 1.139 1.866.04.253.078.499.127.713.234.937.421 1.5.609 1.5s.61-.563 1.031-1.36c.469-.844 0-1.547-.797-1.031.329-2.063 3.563-2.531 3.141-.188-.422 2.391-2.766 4.36-3.469 4.829-.703.468-1.36-.188-1.593-.657-.099-.236-.305-.903-.525-1.615-.303-.98-.632-2.046-.741-2.182-.234-.28-.844.235-.844.235Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
