import { useDispatch, useSelector } from "react-redux";
import DizeeInput from "../../DizeeComponent/DizeeInput";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  setUserArticle,
  updateSocialApiCall,
} from "../../../store/user/userSlice";

import AppleMusic from "../../../svg/Social/AppleMusic";
import Facebook from "../../../svg/Social/Facebook";
import Linkedin from "../../../svg/Social/Linkedin";
import Snapchat from "../../../svg/Social/Snapchat";
import Spotify from "../../../svg/Social/Spotify";
import Thread from "../../../svg/Social/Thread";
import TwitterX from "../../../svg/Social/TwitterX";
import Youtube from "../../../svg/Social/Youtube";
import Instagram from "../../../svg/SocialFeedPlatform/Instagram";
import Tiktok from "../../../svg/SocialFeedPlatform/Tiktok";
const componentMapping = {
  IG: <Instagram />,
  TT: <Tiktok />,
  X: <TwitterX className="h-[15px] w-[15px]" />,
  FB: <Facebook className="h-[15px] w-[15px]" />,
  SC: <Snapchat className="h-[15px] w-[15px]" />,
  YT: <Youtube className="h-[15px] w-[15px]" />,
  SF: <Spotify className="h-[15px] w-[15px]" />,
  AM: <AppleMusic className="h-[15px] w-[15px]" />,
  TH: <Thread className="h-[15px] w-[15px]" />,
  LI: <Linkedin />,
};

export const SocialEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userArticle = useSelector((state) => state.user?.userArticle);
  const [social, setSocial] = useState(
    userArticle?.social
      ? userArticle?.social
      : {
          ig: "",
          tt: "",
          x: "",
          fb: "",
          sc: "",
          yt: "",
          sf: "",
          am: "",
          th: "",
          li: "",
        }
  );

  const handleConfirm = () => {
    dispatch(updateSocialApiCall(true));
    dispatch(setUserArticle({ ...userArticle, social }));
    navigate("/");
  };

  const handleGoBack = () => {
    dispatch(updateSocialApiCall(true));
    navigate("/");
  };

  return (
    <div className="w-full flex justify-center items-center flex-col h-[100vh]">
      <div>
        <div className="font-default mb-[27px]">Confirm or edit your handles</div>

        <div
          className="scrollable-section mb-[68px]"
          style={{ maxHeight: "150px", overflowY: "scroll" }}
        >
          <div className="mb-[27px] flex font-default gap-x-[10px] ">
            {componentMapping["IG"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.ig}
              onChange={(e) => setSocial({ ...social, ig: e.target.value })}
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["TT"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.tt}
              onChange={(e) => setSocial({ ...social, tt: e.target.value })}
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["X"]}
            {/* &nbsp; */}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.x}
              onChange={(e) =>
                setSocial({ ...social, x: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["FB"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.fb}
              onChange={(e) =>
                setSocial({ ...social, fb: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["SC"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.sc}
              onChange={(e) =>
                setSocial({ ...social, sc: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["YT"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.yt}
              onChange={(e) =>
                setSocial({ ...social, yt: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["SF"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.sf}
              onChange={(e) =>
                setSocial({ ...social, sf: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["AM"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.am}
              onChange={(e) =>
                setSocial({ ...social, am: e.target.value })
              }
            />
          </div>
          <div className="mb-[27px] flex font-default gap-x-[10px]">
            {componentMapping["TH"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.th}
              onChange={(e) =>
                setSocial({ ...social, th: e.target.value })
              }
            />
          </div>
          <div className="mb-3 flex font-default gap-x-[10px]">
            {componentMapping["LI"]}
            <DizeeInput
              placeholder={"Username or URL "}
              value={social?.li}
              onChange={(e) =>
                setSocial({ ...social, li: e.target.value })
              }
            />
          </div>
        </div>

        <div className="font-default flex flex-row">
          <div
            className="mr-[43px] cursor-pointer"
            onClick={() => handleConfirm()}
          >
            Confirm
          </div>
          <div className="cursor-pointer" onClick={() => handleGoBack()}>
            Go back
          </div>
        </div>
      </div>
    </div>
  );
};
