import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteMusicThunk, updateMusicThunk, searchMusicForReleaseThunk } from '../../../store/addsection/addsectionThunk';
import { clearAlerts, setErrorAlert, setLoader } from '../../../store/alert/alertSlice';
import { Calendar } from '@nayojs/react-datetime-picker';
import DizeeTimeInput from '../../DizeeComponent/DizeeTimeInput';
import DizeeModal from '../../DizeeComponent/DizeeModal';
import DizeeButton from '../../DizeeComponent/DizeeButton';
import DizeeSelect from '../../DizeeComponent/DizeeSelect';
import { DizeeInput2 } from '../../DizeeComponent/DixeeInput2';
import CopyLink from '../../../svg/CopyLink';
import { removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import { MUSIC_PLATFORMS } from '../../../constants/constants';
import MusicPlatform from './MusicPlatform';


export default function MusicOverlay({ isOverlayVisible, setOverlayVisible, avatarForBackend }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const music = useSelector((state) => state.addsection.music);
    const musicItem = useSelector((state) => state.addsection.searchMusic)
    const index = music.findIndex(music => music.avatar === avatarForBackend)

    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState('11:12');
    const [pm, setPM] = useState(false);
    const [date, setDate] = useState('');
    const [showEditor, setShowEditor] = useState(false)
    const [showPlayback, setShowPlayback] = useState(false)
    const [showPlatform, setShowPlatform] = useState(false)
    const [playback, setPlayback] = useState(true)
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [link, setLink] = useState('');

    const selectDateHandler = (date) => {
        setDate(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
    }
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            setTime(val);
        }
    }
    const onSaveSchedule = () => {
        dispatch(clearAlerts());
        if (date.length < 1 || time.length < 5) {
            return;
        }
        const saveType = "schedule"
        const timer = `${date} ${time} ${pm ? 'pm' : 'am'}`
        dispatch(updateMusicThunk({ timer, avatarForBackend, saveType, token }));
        setOverlayVisible(false);
    }

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    const handleEditorClick = () => {
        dispatch(clearAlerts());
        setShowEditor(true)
    }

    const handlePlaybackClick = () => {
        dispatch(clearAlerts());
        setShowPlayback(true)
    }

    const handlePlatformClick = () => {
        dispatch(clearAlerts());
        let search = music[index].title + " "

        dispatch(searchMusicForReleaseThunk({ token: token, search: search }));

        setShowPlatform(true)
    }

    const handleScheduleClick = () => {
        dispatch(clearAlerts());
        setShowCalendar(true)
    };

    const handlePublishClick = () => {
        const saveType = "publish"
        dispatch(clearAlerts());
        dispatch(updateMusicThunk({ avatarForBackend, saveType, token }));
        setOverlayVisible(false);
    };

    const handleDeleteClick = () => {
        dispatch(clearAlerts());
        dispatch(deleteMusicThunk({ token: token, avatar: avatarForBackend }));
        setOverlayVisible(false);
    };

    const handleTogglePlatform = (platform) => {
        setSelectedPlatforms(prevSelected =>
            prevSelected.includes(platform)
                ? prevSelected?.filter(item => item !== platform)
                : [...prevSelected, platform]
        );
    };

    const onSaveEdit = () => {
        dispatch(clearAlerts())
        if (!link.length > 0 && !selectedPlatforms.length > 0) {
            dispatch(setErrorAlert("Please select platform."))
            return
        }

        if (selectedPlatforms?.length > 0) {
            const selectedLinks = musicItem[index]?.links?.filter(link => selectedPlatforms.includes(link.source)) || [];
            dispatch(updateMusicThunk({ avatarForBackend, playback, selectedLinks, token }));
        } else if (link?.length > 0) {
            dispatch(updateMusicThunk({ avatarForBackend, link, token }));
        }
        setLoader(true)
        setSelectedPlatforms([])
        setLink('')
        setLoader(false)
        setShowEditor(false);
    }

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >

            {music[index].publish.value === "draft" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Publish</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : music[index].publish.value === "schedule" ? <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleScheduleClick()}>Edit Schedule</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handlePublishClick()}>Publish</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div> : <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" onClick={e => e.stopPropagation()}>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleEditorClick()}>Edit details</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => handleDeleteClick()}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>}

            {showCalendar &&
                <DizeeModal isOverlayVisible={showCalendar} setOverlayVisible={setShowCalendar}>
                    <div>
                        <Calendar selectDateHandler={selectDateHandler} calendarStyles={{
                            containerStyles: { backgroundColor: '#000' },
                            dateStyles: { color: '#fff' },
                            selectedDateStyles: { backgroundColor: '#000', color: '#ff2' },
                            headerStyles: { background: '#000' },
                            headerTitleTextStyles: { color: '#fff' },
                            headerTitleStyles: { color: '#fff' },
                        }} />
                        <div className='flex'>
                            <DizeeTimeInput
                                name="example"
                                initTime='11:12'
                                className='s-input -time dizee-input text-white pl-[10px] w-[50px]'
                                mountFocus='true'
                                onTimeChange={onTimeChangeHandler}
                            />
                            <div className='flex gap-x-2'>
                                <div className='w-[50px]'><DizeeSelect selected={!pm} txt={'AM'} onSelect={() => setPM(false)} checkmark={true} /></div>
                                <div><DizeeSelect selected={pm} txt={'PM'} onSelect={() => setPM(true)} checkmark={true} /></div>
                            </div>
                        </div>
                        <div className='flex gap-x-3 mt-[20px] pl-[10px]'>
                            <DizeeButton label={'Confirm'} onClick={onSaveSchedule} />
                            <DizeeButton label={'Go back'} onClick={() => setShowCalendar(false)} />
                        </div>
                    </div>
                </DizeeModal>
            }

            {showEditor && <DizeeModal isOverlayVisible={showEditor} setOverlayVisible={setShowEditor}>
                <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                    <div className='px-4 my-[50px] flex w-full justify-between'>
                        <p className='cursor-pointer text-white'>Edit details</p>
                        <div className='cursor-pointer flex gap-x-4'>
                            <DizeeButton label={'Confirm'} onClick={() => onSaveEdit()} />
                            <DizeeButton label={'Go back'} onClick={() => setShowEditor(false)} />
                        </div>
                    </div>
                    <div className='px-4 my-[80px] flex flex-col w-full'>
                        <p className='cursor-pointer text-white py-6'>{music[index].title}</p>
                        <p className='cursor-pointer text-white py-6'>{music[index].artist}</p>
                        <div className='cursor-pointer text-white flex items-center justify-between'>
                            <p>Change cover image</p>
                            <img src={removeUploadsFromUrl(music[index]?.avatar)}
                                alt='Music Cover'
                                className='object-cover h-[50px] w-[50px] rounded-[3px]' />
                        </div>
                        {music[index].type === 0 ?
                            <div><p className='cursor-pointer text-white py-6' onClick={() => handlePlaybackClick()}>Playback options</p>
                                <p className='cursor-pointer text-white py-6' onClick={() => handlePlatformClick()}>Platform display</p></div> :
                            <div className='py-10 pb-[40px] flex w-full justify-between items-center'>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <DizeeInput2
                                        label="Link"
                                        placeholder="Enter a link"
                                        className="dizee-input w-full"
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                    />
                                </div>
                                <CopyLink className='h-[14px] w-[14px] mx-1' />
                            </div>
                        }
                    </div>
                </div>
            </DizeeModal>
            }
            {showPlayback && <DizeeModal isOverlayVisible={showPlayback} setOverlayVisible={setShowPlayback}>
                <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                    <div className='px-4 my-[50px] flex w-full justify-between'>
                        <p className='text-white'>Playback options</p>
                        <DizeeButton onClick={() => setShowPlayback(false)} label={'Next'} />
                    </div>
                    {
                        <div className='w-full'>
                            <DizeeSelect selected={playback} txt={'Enable'} onSelect={() => setPlayback(true)} checkmark={true} />
                            <DizeeSelect selected={!playback} txt={'Disable'} onSelect={() => setPlayback(false)} checkmark={true} />
                        </div>
                    }
                </div>
            </DizeeModal>
            }
            {showPlatform && <DizeeModal isOverlayVisible={showPlatform} setOverlayVisible={setShowPlatform}>
                <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                    <div className='px-4 my-[50px] flex w-full justify-between'>
                        <p className='text-white'>Select a platform for preview</p>
                        <DizeeButton onClick={() => setShowPlatform(false)} label={'Next'} />
                    </div>
                    {
                        MUSIC_PLATFORMS.map((music, musicIndex) => (
                            <MusicPlatform
                                txt={music.label}
                                key={`${musicIndex}-${musicIndex}`} // Ensure unique keys for each music
                                selected={selectedPlatforms.includes(music.value)}
                                onSelect={() => handleTogglePlatform(music.value)}
                            />
                        ))
                    }
                </div>
            </DizeeModal>
            }
        </div>
    )
}
