(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("@nayojs/react-datetime-picker", ["react"], factory);
	else if(typeof exports === 'object')
		exports["@nayojs/react-datetime-picker"] = factory(require("react"));
	else
		root["@nayojs/react-datetime-picker"] = factory(root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__) => {
return 