import React from 'react'
import more from '../../../assets/images/components/More.png';
import More from '../../../svg/More';


const Card = ({ title, desc, photo, saveType, setOverlayVisible, setSaveType, setLink, setTitleState, timeBackend, setTimerBackend, item }) => {
    return (
        <div className='w-full  p-5 flex flex-row justify-between'>
            <div className='flex flex-row justify-start items-center'>
                <video src={photo} style={item.filter ? { filter: item.filter } : null} className="object-cover h-[40px] w-[40px] rounded-full mr-5 " muted controls={false} />
                <div className='flex flex-col items-start justify-start  p-2'>
                    <p className='text-white' style={{ fontSize: '12px' }}>{title}</p>
                </div>
            </div>
            <div className='flex flex-row w-auto items-center'>
                {saveType === 'draft' && <p className='text-white' style={{ fontSize: '12px' }}>draft</p>}
                {saveType === 'schedule' && <p className='text-white' style={{ fontSize: '12px' }}>scheduled</p>}
                {saveType === 'publish' && <p className='text-white' style={{ fontSize: '12px' }}>published</p>}

                <More className='h-[20px] w-[20px] cursor-pointer ml-7 ' onClick={() => {
                    setOverlayVisible(true)
                    setSaveType(saveType)
                    setLink(photo)
                    setTitleState(title)
                    setTimerBackend(timeBackend)
                }} />
            </div>
        </div>
    )
}

export default Card
