"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateMinutes = exports.generateHours = void 0;
var date_fns_1 = require("date-fns");
var generateHours = function () {
    var hours = [];
    for (var i = 0; i < 24; i++) {
        hours.push((0, date_fns_1.setHours)(new Date(), i));
    }
    return hours;
};
exports.generateHours = generateHours;
var generateMinutes = function () {
    var minutes = [];
    for (var i = 0; i < 60; i++) {
        minutes.push((0, date_fns_1.setMinutes)(new Date(), i));
    }
    return minutes;
};
exports.generateMinutes = generateMinutes;
