"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateCalendarDays = exports.generateYears = void 0;
var date_fns_1 = require("date-fns");
var generateYears = function () {
    var years = [];
    for (var i = 2100; i >= 1900; i--) {
        years.push(i);
    }
    return years;
};
exports.generateYears = generateYears;
var generateCalendarDays = function (month) {
    var start = (0, date_fns_1.startOfWeek)((0, date_fns_1.startOfMonth)(month));
    var end = (0, date_fns_1.endOfWeek)((0, date_fns_1.endOfMonth)(month));
    var days = [];
    var day = start;
    while (day <= end) {
        days.push(day);
        day = (0, date_fns_1.addDays)(day, 1);
    }
    return days;
};
exports.generateCalendarDays = generateCalendarDays;
