import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutPersonalHeader from "../../layout/LayoutPersonalHeader";
import { follow_unfollow_auth_thunk } from "../../store/auth/authThunk";
import { followProUserThunk } from "../../store/user/userThunk";
import { setUserArticle } from "../../store/user/userSlice";
import More from "../../svg/More";
import CrossArrow from "../../svg/CrossArrow";
import BottomBar from "../../components/Personal/BottomBar";
import { removeUploadsFromUrl } from "../../components/DizeeComponent/DixeeInput2";
import { getExploreDataThunk } from "../../store/user/userThunk";
import { SOCIAL_PLATFORMS_SVG } from "../../constants/constants";

import "../../css/slide.css";

import TinderCard from "react-tinder-card";

export default function Explore() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [curUser, setUser] = useState({});
  const userArticle = useSelector((state) => state.user.userArticle); // Currently logged-in user
  let token = localStorage.getItem("dizeeToken");

  // Fetch random users
  useEffect(() => {
    dispatch(getExploreDataThunk({ userArticle, token }))
      .then((res) => {
        if (res?.payload?.status === 200) {
          setUsers(res.payload?.data.data);
          res.payload?.data.data.length > 1 &&
            setUser(res.payload?.data.data[0]);
          console.log(res.payload?.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userArticle?.username, dispatch]);

  // Handle follow/unfollow logic
  const handleFollowUnfollow = async (followerId, isFollowing, follower) => {
    dispatch(
      follow_unfollow_auth_thunk({
        username: userArticle?.username,
        user: follower,
        
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          // Update the followers list to reflect the follow/unfollow change
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user._id === followerId
                ? { ...user, isFollowing: !isFollowing } // Toggle follow/unfollow status
                : user
            )
          );

          // Update the global user state with the updated follower list
          dispatch(setUserArticle(res?.payload?.data?.response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSwipe = async (direction, user) => {
    console.log("You swiped: " + direction);
    console.log("Swiped user: ", user);

    if (direction == 'right')

    dispatch(
      followProUserThunk({
        user: userArticle,
        target: user,
        token
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          console.log(`onSwipe::`, res?.payload?.data?.response);
          // Update the followers list to reflect the follow/unfollow change
          // setUsers((prevUsers) =>
          //   prevUsers.map((user) =>
          //     user._id === followerId
          //       ? { ...user, isFollowing: !isFollowing } // Toggle follow/unfollow status
          //       : user
          //   )
          // );

          // // Update the global user state with the updated follower list
          // dispatch(setUserArticle(res?.payload?.data?.response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCardLeftScreen = (myIdentifier) => {
    console.log(myIdentifier + " left the screen");
  };

  const getPlatformSrc = (value) => {
    const platform = SOCIAL_PLATFORMS_SVG.find((p) => p.value === value);
    if (!platform) return null;

    return {
      Component: platform.Component,
      redirect: platform.redirect,
    };
  };

  const handleSocialClick = (redirect, handle) => {
    const url = `${redirect}${handle}`;
    window.open(url, "_blank");
  };

  return (
    <LayoutPersonalHeader>
      <div className="w-[300px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black">
        {/* <Swiper onSlideChange={(e) => console.log(e)}>
          {users.map((user, index) => (
            <SwiperSlide key={user._id}>
              <div
                className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
                style={
                  curUser?.background
                    ? { backgroundColor: `#${user?.background}` }
                    : { backgroundColor: "#000000" }
                }
              >
                <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative rounded-t-[20px] rounded-br-[50px] overflow-hidden">
                  <img
                    src={removeUploadsFromUrl(`${user?.avatar}`)}
                    alt="photoimage"
                    className="w-full h-full object-cover"
                  />
                  <img
                    className="w-[60px] h-[60px] rounded-full absolute right-[6px] bottom-[6px] z-[10] border border-white"
                    src={removeUploadsFromUrl(`${user?.avatar}`)}
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end pl-4"
                    style={{
                      backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                    }}
                  >
                    <div className="flex flex-col justify-center pb-2">
                      <p
                        className="text-xl text-white font-bold"
                        style={{ color: "#FBFBFD" }}
                      >
                        {user?.username}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center my-[68px] gap-x-[100px] font-default">
                  <div className="cursor-pointer">Skip</div>
                  <div className="cursor-pointer">Follow</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}

        {users.map((user, idx) => (
          <TinderCard
            key={user._id}
            onSwipe={async (direction) => await onSwipe(direction, user)}
            className="swipe"
            onCardLeftScreen={() => onCardLeftScreen("fooBar")}
            // preventSwipe={["right", "left"]}
          >
            <div
              className="w-[300px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
              style={
                // user?.background
                //   ? { backgroundColor: `#${user?.background}` }
                //   : { backgroundColor: "#000000" }
                { backgroundColor: "#000000" }
              }
            >
              <div className="max-w-[300px] sm:max-w-[390px] h-[420px] flex relative rounded-t-[20px] rounded-br-[50px] overflow-hidden">
                <img
                  src={removeUploadsFromUrl(`${user?.avatar}`)}
                  alt="photoimage"
                  className="w-full h-full object-cover tinder"
                />
                <img
                  className="w-[60px] h-[60px] rounded-full absolute right-[6px] bottom-[6px] z-[10] border border-white tinder"
                  src={removeUploadsFromUrl(`${user?.avatar}`)}
                  alt=""
                />
                <div
                  className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end pl-4"
                  style={{
                    backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                  }}
                >
                  <div className="flex flex-col justify-center pb-2">
                    <div
                      className="text-xl flex flex-row gap-x-[5px] items-center text-white font-bold tinder-p"
                      style={{ color: "#FBFBFD" }}
                    >
                      {user?.username}
                      <CrossArrow className="h-[12px] w-[12px] mt-[5px] cursor-pointer" />
                    </div>
                    <div className="flex flex-row mt-2  space-x-2 ">
                      {user?.social &&
                        Object.keys(user.social).map((key) => {
                          const platformData = getPlatformSrc(key);

                          if (platformData && platformData.Component) {
                            const SocialIcon = platformData.Component;
                            return (
                              <div
                                key={key}
                                className="h-[15px] w-[15px]cursor-pointer"
                                onClick={() =>
                                  handleSocialClick(
                                    platformData.redirect,
                                    userArticle.social[key]
                                  )
                                }
                              >
                                <SocialIcon
                                  className="w-[15px] h-[15px] cursor-pointer"
                                  color={"#FBFBFD"}
                                />
                              </div>
                            );
                          }

                          return null;
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center my-[40px] sm:my-[68px] gap-x-[100px] font-default">
                <div className="cursor-pointer">Skip</div>
                <div className="cursor-pointer">Follow</div>
              </div>
            </div>
          </TinderCard>
        ))}

        <BottomBar />
      </div>
    </LayoutPersonalHeader>
  );
}
