import React from 'react'

export default function Instagram(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <g fill="#fff" clipPath="url(#a)">
                <path d="M7.504 1.35c2.005 0 2.243.01 3.032.045.732.032 1.128.155 1.392.258.349.134.6.299.862.56.264.263.425.512.56.86.102.265.225.663.258 1.393.035.79.044 1.028.044 3.03 0 2.004-.01 2.241-.044 3.03-.033.732-.156 1.128-.258 1.391a2.32 2.32 0 0 1-.56.862 2.307 2.307 0 0 1-.862.56c-.264.102-.663.225-1.393.257-.791.036-1.028.044-3.03.044-2.006 0-2.243-.008-3.032-.044-.733-.032-1.128-.155-1.392-.258a2.32 2.32 0 0 1-.862-.56 2.308 2.308 0 0 1-.56-.86c-.102-.264-.226-.663-.258-1.393-.035-.79-.044-1.028-.044-3.03 0-2.004.009-2.241.044-3.03.032-.732.156-1.127.258-1.391.135-.349.3-.6.56-.862.264-.263.513-.425.862-.56.264-.102.662-.225 1.392-.257.789-.035 1.026-.044 3.031-.044Zm0-1.35C5.467 0 5.212.009 4.412.044c-.798.035-1.346.164-1.82.349a3.663 3.663 0 0 0-1.332.867c-.419.416-.674.835-.867 1.327-.185.478-.314 1.023-.349 1.82C.009 5.21 0 5.465 0 7.501c0 2.037.009 2.292.044 3.092.035.797.164 1.345.349 1.82.193.495.448.914.867 1.33.417.416.836.674 1.328.864.478.185 1.023.314 1.82.349.801.035 1.056.044 3.093.044 2.038 0 2.293-.009 3.093-.044.797-.035 1.346-.164 1.82-.349.493-.19.912-.448 1.328-.864.417-.416.675-.835.865-1.328.185-.477.314-1.022.349-1.82.035-.8.044-1.054.044-3.09 0-2.037-.009-2.292-.044-3.092-.035-.797-.164-1.345-.349-1.82a3.514 3.514 0 0 0-.859-1.333A3.67 3.67 0 0 0 12.42.396C11.943.21 11.397.082 10.6.046 9.797.01 9.542 0 7.504 0Z" />
                <path d="M7.5 3.75a3.75 3.75 0 0 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 6.183a2.433 2.433 0 1 1 0-4.866 2.433 2.433 0 0 1 0 4.866ZM12.188 3.516a.703.703 0 1 1-1.407 0 .703.703 0 0 1 1.406 0Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
